/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Realmente la blogosfera en el ámbito de control e ingeniería de sistemas es prácticamente nula, no es algo especifico del control, pasa con muchas ", React.createElement(_components.a, {
    href: "http://arrsa.org/2013/03/hacia-una-blogosfera-sobre-urbanismo-y-planeamiento-territorial/"
  }, "otras disciplinas"), ", sobretodo esto ocurre en las técnicas que más relación tienen con la universidad, son más teóricas y abstractas y además no han surgido colectivos hackers que las difundan en la red."), "\n", React.createElement(_components.p, null, "Es cierto que la ingeniería de control además no es nada conocida, está eclipsada por la electrónica o la robótica y a veces tengo problemas para explicar lo que hago, sí es cierto que estudio electrónica, programación, sistemas de producción, robótica…pero donde está realmente el centro es en el campo del control y la ingeniería de sistemas."), "\n", React.createElement(_components.p, null, "Y mientras no se forma una blogosfera del tema en otros campos nos toman la delantera, haciendo que las investigaciones estén cada vez más al alcance de todos. Podría destacar los blogs sobre ", React.createElement(_components.a, {
    href: "http://mipsandchips.blogspot.com.es/"
  }, "electrónica"), ", ", React.createElement(_components.a, {
    href: "http://electrolito.entramado.net/"
  }, "cacharreo"), ", ", React.createElement(_components.a, {
    href: "http://iot-datamodels.blogspot.com/"
  }, "comunicaciones"), ", ", React.createElement(_components.a, {
    href: "http://plmtwine.com/"
  }, "producción"), " y muchas más cosas."), "\n", React.createElement(_components.p, null, "Por eso tengo la tentación de tirar en este blog hacia la rama de control, y ya he hecho algunos avances, como veo que se va consolidando he convertido la etiqueta ", React.createElement(_components.a, {
    href: "http://litox.entramado.net/category/ingenieria-de-control/"
  }, "Ingeniería de Control"), " en una categoría del blog, por supuesto no va a ser un blog monotemático porque nunca lo ha sido pero sí es posible que hable más profundamente de estos temas en próximos posts."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
